<template>
  <div class="container-fluid mt-auto footer" >
    <div class="row justify-content-center" id="social-footer">
      <div class="p-0 col-12 justify-content-center my-5">
        <p class="text-center  ">
          {{ $t("¡SÍGUENOS EN NUESTRAS REDES!") }}
        </p>
        <ul class="nav justify-content-center">
          <li v-for="(social,index) in socials" v-bind:key="index+'icon'" class="nav-item nav-link">
            <a :href="social.link">
              <i :class="social.icon" class="social-icon"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="container-fluid">

      <div class="row justify-content-center" id="footer">
        <div class="col-4 mx-auto text-center my-3">
          <img src="@/assets/img/viendomovies-logo-white.png" alt="Viendo Movies Logo" width="58px" height="80px">
        </div>
      </div>

      <div class="row justify-content-center mb-2">
        <ul class="nav justify-content-center " id="nav-footer">
          <li
              v-for="(route,index) in menuHeader"
              v-bind:key="index"
              class="nav-item">
            <router-link
                class="nav-link"
                :to="route.linkType == 'page' ? {
                  name: 'page',
                  params:{
                    page: route.routerName
                  }
                } : route.routerName"
            >
              {{route.linkName.toUpperCase()}}
            </router-link>
          </li>
        </ul>
      </div>

      <div class="row justify-content-center">
        <ul class="nav justify-content-center ">
          <li v-for="(route,index) in menuBottom" v-bind:key="index" class="nav-item">
            <router-link class="nav-link" 
            :to="route.linkType == 'page' ? {
              name: 'page',
              params:{
                page: route.routerName
              }
            } : route.routerName">{{route.linkName.toUpperCase()}}</router-link>
          </li>

          <li class="nav-item">
            <a v-if="$i18n.locale == 'en'" class="nav-link" @click.prevent="changeLocale('es')" href="#"><i class="bi bi-globe"></i> {{ $t("Español").toUpperCase() }}</a>
            <a v-else class="nav-link" @click.prevent="changeLocale('en')" href="#"><i class="bi bi-globe"></i> {{ $t("English").toUpperCase() }}</a>
          </li>
        </ul>
      </div>
      <div class="row justify-content-center">
        <div class="text-center my-4">
          <img
              class="mx-auto d-block"
              src="@/assets/img/somos-tv-logo-white.png"
              alt="Somos Tv Logo">
        </div>
      </div>
        
      <div class="row justify-content-center">
        <p class="my-3 small" id="copyright">© {{ new Date().getFullYear() }} {{ $t("Viendo Movies - Todos los derechos reservados") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "AppFooter",
  computed:{
    ...mapGetters(["footer_menu", "header_menu"]),
    menuBottom(){
      return this.footer_menu.map((m) => {
        return {
          routerName : m.slug,
          linkName : m.title,
          linkType: m.type
        }
      })
    },
    menuHeader(){
      return this.header_menu.map((m) => {
        return {
          routerName : m.slug,
          linkName : m.title,
          linkType: m.type
        }
      })
    }
  },
  methods: {
    changeLocale(locale){
      this.$i18n.locale = locale;
      this.$store.commit('setLocale', locale);
      window.location.reload();
    }
  },
  data() {
    return {
      // menuTop: [
      //   {
      //     routerName : "aboutUs",
      //     linkName : "Quiénes Somos"
      //   },
      //   {
      //     routerName : "adquisitions",
      //     linkName : "Adquisiciones"
      //   },
      //   {
      //     routerName : "jobs",
      //     linkName : "Empleos"
      //   },
      //   {
      //     routerName : "contact",
      //     linkName : "Contáctanos"
      //   },
      //   {
      //     routerName: "faqs",
      //     linkName: "FAQS"
      //   },
      // ],
      // menuBottom: [
      //   {
      //     routerName : "privacy",
      //     linkName : "Política de Privacidad"
      //   },
      //   {
      //     routerName : "terms",
      //     linkName : "Términos y Condiciones"
      //   },
      // ],
      socials : [
        {
          icon: "bi-facebook",
          link: "https://www.facebook.com/ViendoMovies",
        },
        {
          icon: "bi-twitter-x",
          link: "https://twitter.com/ViendoMovies",
        },
        {
          icon: "bi-instagram",
          link: "https://www.instagram.com/viendo.movies",
        },
        {
          icon: "bi-youtube",
          link: "https://www.youtube.com/c/ViendoMoviesChannel",
        }
      ]
    }
  }
}
</script>

<style lang="scss">
@import "src/assets/styles/colors";

.footer{
  .social-icon{
    text-align: center;
    color: $white;
    font-size: 1.2em;
    padding: .5em;
    border-radius: 100px;
    background-color: $light-blue;
    &:before{
      vertical-align: -.25em;
      font-size: 1.2em;
      text-align: center;
      width: 1em;
      height: 1em;
    }
  }
}

.bi-twitter-x:before{
  content:'';
  background-image: url('/img/cine-club/twitter-x.png');
  background-size: cover;
  height: 12px;
}

</style>